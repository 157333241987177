
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.lightbox = require('lightbox2');
lightbox.option({
  'positionFromTop': 200,
  'fadeDuration': 0,
  'resizeDuration': 0,
  'disableScrolling': true,
  'wrapAround': true
});
